import * as React from "react"
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import MainArea from  '../components/MainArea';
import ColouredGrid from "../components/ColouredGrid";
import GridPane from "../components/GridPane";
import SideBySide from "../components/SideBySide";
import NextBanner from "../components/NextBanner";
import {Helmet} from 'react-helmet';

import { StaticImage } from "gatsby-plugin-image";

// videos
import OverallVideo from '../assets/content/videos/overall.mp4';
import LauraVideo from '../assets/content/videos/laura.mp4';
import RichVideo from '../assets/content/videos/rich.mp4';
import BaranseVideo from '../assets/content/videos/baranse.mp4';

// markup
const BenefitsPage = () => {
    return (
        <Layout>

            <Helmet>
                <title>Benefits | Bluetree Group</title>
                <meta name="description" content="Key benefits of the Bluetree Graduate Management Programme" />
            </Helmet>                        
            <Banner title="Accelerate your career with Bluetree Group">
                <StaticImage src={"../assets/banners/benefits-banner.jpg"} alt="Employee sitting at machine" />                                
            </Banner>    
            <MainArea>
                <h2 className='intro'>Key benefits of the Bluetree Graduate Management Programme include:</h2>
                <ColouredGrid>
                    <GridPane>
                        <img src='/vectors/rocket.svg' alt="rocket" />
                        <p>Structured 12-month rotational programme through divisions, providing an opportunity to experience different businesses and roles</p>
                        <p>Early chance to demonstrate your capabilities through individual business projects</p>
                        <p>Supported by board members and senior leadership team</p>
                        <p>Focused leadership training delivered by internal and external experts</p>
                    </GridPane>
                    <GridPane>
                        <img src='/vectors/diamond.svg' alt="diamond" />
                        <p>Starting salary of £30,000</p>
                        <p>20 days annual leave (plus bank holidays) and the opportunity to earn up to 29 days, the longer you work here</p>
                        <p>Health cash plan</p>
                        <p>Critical illness cover</p>
                    </GridPane>
                    <GridPane>
                        <img src='/vectors/heart.svg' alt="heart" />
                        <p>Wellbeing support, including access to mental health support lines and personal skills workshops such as managing your money</p>
                        <p>Cycle to work scheme</p>
                        <p>Free fruit, snacks and beverages in the Bluetree canteen</p>
                        <p>Regular social events and team-building activities, such as our annual sports day</p>
                    </GridPane>
                    <GridPane>
                        <img src='/vectors/trees.svg' alt="trees" />
                        <p>Pride in being part of:</p>
                        <ul>
                            <li>an award-winning, industry leading, dynamic and innovative company on a fast-track for growth</li>
                            <li>a sustainable, responsible business that will be carbon neutral in 2021, achieve net zero by 2030, and uses only renewable energy in its facilities. We’ve achieved the ISO14001 standard for our Environmental Management System</li>
                            <li>an organisation with a social conscience. We pay the real living wage for all employees, and we support good causes that matter to us, from Bluebell Wood children’s hospice to Crisis.</li>
                            <li>a community which looks out for each other. Whether through providing access to mental health support lines, access to personal finance experts, or just being part of a team that values everyone’s contribution</li>
                        </ul>
                    </GridPane>
                </ColouredGrid>
                <SideBySide>
                    <div>
                        <h2>An overview</h2>
                        <p>Situated in the heartland of the northern powerhouse, Bluetree Group is a company on the fast track to excellence.</p>
                        <p>We’ve exceeded expectations and achieved incredible things, despite the global challenges of recent years.</p>
                        <p>That’s in no small part down to our amazing people. All 670 of us.</p>
                        <p>Hard work and leadership are rewarded at Bluetree.</p>
                        <p>And opportunities to advance your career in new fields regularly arise.</p>
                        <p>We encourage having fun and playing together.</p>
                        <p>And we strive to deliver a positive social impact.</p>
                    </div>
                    <StaticImage  src={"../assets/content/map.png"} alt="Map of office"></StaticImage>
                </SideBySide>
                <SideBySide style={{paddingBottom: "0px"}} className="consecutive">
                    <StaticImage width="600" src={"../assets/content/customer-service.jpg"} />
                    <StaticImage width="600" src={"../assets/content/celebrating.jpg"} />
                </SideBySide>
                <div className='videos'>
                    <h2>We think we're a fantastic place to work.<br />But why not hear it from our team?</h2>
                    <div className='video-list'>
                    <video controls poster="/videoposters/overall.jpg">                        
                        <source src={OverallVideo} type="video/mp4" />
                    </video>   
                    <video controls poster="/videoposters/laura.jpg">
                        <source src={LauraVideo} type="video/mp4" />
                    </video>    
                    <video controls poster="/videoposters/rich.jpg">
                        <source src={RichVideo} type="video/mp4" />
                    </video>   
                    <video controls poster="/videoposters/baranse.jpg">
                        <source src={BaranseVideo} type="video/mp4" />
                    </video>   
                    </div>
                </div>                
                <NextBanner destination="/aboutyou">
                    <strong>Next:</strong> Do you have what it takes?
                </NextBanner>
            </MainArea>
        </Layout>      
    )
  }
  
  export default BenefitsPage
  