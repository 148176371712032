import * as React from "react"


const ColouredGrid = ({ children }) => {
    return (
      <div className='coloured-grid'>
          { children }
      </div>   
    )
}
  
export default ColouredGrid