import * as React from "react"


const Banner = ({ children, title }) => {

    let titleArea = null;
    if(title) {
        titleArea = <div className="heading-area">
        <h1>{ title }</h1>
      </div>    
    } 
    return (
      <div className='banner'>
          { children }
          { titleArea }
      </div>

    )
}
  
export default Banner