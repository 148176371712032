import * as React from "react"


const MainArea = ({ children }) => {
    return (
      <main className='content'>
          { children }
      </main>   
    )
}
  
export default MainArea