import * as React from "react"
import {Link} from "gatsby";


const NextBanner = ({ children, style, destination }) => {
    return (
      <div className='next-banner' style={style}>
        <Link to={destination}>
          { children }
        </Link>
      </div>         
    )
}
  
export default NextBanner