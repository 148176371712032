import * as React from "react"


const SideBySide = ({ children, style, className }) => {
    return (
      <div className={'side-by-side ' + (className ? className : "")} style={style} >
          { children }
      </div>   
    )
}
  
export default SideBySide